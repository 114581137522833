import { Address } from "../../../../interface/src";

export const formatAddressWithLine1 = (address: Address): string | undefined => {
  if (!address || !address.formatted) {
    return undefined;
  }
  if (!address.line1) {
    return address.formatted;
  }

  const { line1, formatted } = address;
  if (formatted.indexOf(",") === -1) {
    return `${line1}, ${formatted}`;
  }

  const elements = formatted.split(",");

  // Example formatted address returned from the api is "478 Smith St, Brooklyn, NY 11231"
  // Previous API versions apparently were adding "USA" component at the end, but it's not a case anymore
  // so 3 components is a full address
  if (elements.length < 3) {
    return `${line1}, ${formatted}`;
  }

  elements[0] = `${elements[0]} ${line1}`;
  return elements.join(",");
};
