import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonItem,
  IonLabel,
  IonSkeletonText,
} from "@ionic/react";
import { range } from "lodash";
import { FC, Fragment } from "react";

interface ShiftLoaderProps {
  loading: boolean;
  count?: number;
}

/**
 * @deprecated
 */
export const ShiftLoader: FC<ShiftLoaderProps> = ({ loading, count = 3 }) => {
  const cardCount = loading ? count : 0;

  return (
    <Fragment>
      {range(cardCount).map((index) => (
        <IonCard mode="ios" key={index}>
          <IonCardHeader>
            <IonCardSubtitle>
              <IonSkeletonText animated />
              <IonSkeletonText animated style={{ width: "70%" }} />
            </IonCardSubtitle>
          </IonCardHeader>
          <IonItem lines="none">
            <IonLabel>
              <h3>
                <IonSkeletonText animated />
              </h3>
              <p>
                <IonSkeletonText animated style={{ width: "70%" }} />
              </p>
              <p>
                <IonSkeletonText animated style={{ width: "50%" }} />
              </p>
            </IonLabel>
          </IonItem>
        </IonCard>
      ))}
      {loading && <div data-testid="shift-loader" />}
    </Fragment>
  );
};
