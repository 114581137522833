import { IonChip, IonLabel } from "@ionic/react";

import { type HasShift, getHasShiftColor } from "./utils";

export { getHasShiftColor };

interface ShiftChipProps {
  name: string;
  hasShift?: HasShift;
  outline?: boolean;
  "data-testid"?: string;
}
/**
 * @deprecated
 */
export function ShiftChip(props: ShiftChipProps) {
  const { name, hasShift, outline } = props;
  return (
    <IonChip
      color={getHasShiftColor(hasShift, name, outline)}
      className="small-chip"
      outline={outline}
      data-testid={props["data-testid"]}
    >
      <IonLabel>{name ? name.toUpperCase() : ""}</IonLabel>
    </IonChip>
  );
}
