import firebase from "firebase/app";
import "firebase/auth";

export class Firebase {
  protected FirebaseApp: firebase.app.App;
  constructor(firebaseConfig: Object) {
    if (!firebase.apps.length) {
      this.FirebaseApp = firebase.initializeApp(firebaseConfig);
    } else {
      this.FirebaseApp = firebase.app();
    }
  }
}
