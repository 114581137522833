import { ShiftPeriod } from "@src/appV2/Shifts/Shift/types";

/**
 * @deprecated
 */
function getShiftColor(shiftName: string): string {
  switch (shiftName) {
    case ShiftPeriod.AM:
      return "shift-am";
    case ShiftPeriod.PM:
      return "shift-pm";
    case ShiftPeriod.NOC:
      return "shift-noc";
    default:
      return "shift-custom";
  }
}

/**
 * @deprecated
 * `HasShift` is a legacy name, and is being reworked.
 */
export interface HasShift {
  hasStaffedShift: boolean;
  hasOpenShift: boolean;
  hasPriorityShift: boolean;
}

/**
 * @deprecated
 */
export function getHasShiftColor(
  hasShift: HasShift | undefined,
  shiftName: string,
  outline?: boolean
): string {
  if (!hasShift) {
    return getShiftColor(shiftName);
  }

  if (hasShift.hasStaffedShift) {
    return "success";
  }

  if (hasShift.hasOpenShift) {
    if (hasShift.hasPriorityShift) {
      return "shift-priority";
    } else {
      return getShiftColor(shiftName);
    }
  }

  return outline ? "medium" : "light";
}
