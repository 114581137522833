import { IonIcon, IonNote } from "@ionic/react";
import { homeOutline, navigate } from "ionicons/icons";
import { FC } from "react";

interface DistanceNoteProps {
  distance: number;
  homeIcon?: boolean;
  isApproxDistance?: boolean;
}

/**
 * @deprecated
 */
export const DistanceNote: FC<DistanceNoteProps> = ({
  distance,
  homeIcon = false,
  isApproxDistance = false,
}) => (
  <IonNote style={{ fontSize: "10px", display: "inline-block" }}>
    <IonIcon icon={homeIcon ? homeOutline : navigate} mode="md" /> &nbsp;
    {`${isApproxDistance ? "~" : ""}${distance ? distance.toFixed(1) : "0"} mile(s) away`}
  </IonNote>
);
