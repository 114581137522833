import { isDefined } from "@clipboard-health/util-ts";
import { Address, Location } from "@src/lib/interface/src";
import { isEmpty, mapValues } from "lodash";
import { getGeocode } from "use-places-autocomplete";

export const LA_CENTER = { lat: 34.0522, lng: -118.2437 };

interface AddressIdAndValues {
  id: string;
  values: string[];
}

const ADDRESS_ID_AND_VALUES: AddressIdAndValues[] = [
  { id: "streetNumber", values: ["street_number"] },
  { id: "streetName", values: ["street_address", "route"] },
  { id: "postalCode", values: ["postal_code"] },
  { id: "subpremise", values: ["subpremise"] },

  {
    id: "region",
    values: [
      "administrative_area_level_2",
      "administrative_area_level_3",
      "administrative_area_level_4",
      "administrative_area_level_5",
      "sublocality",
      "sublocality_level_1",
      "sublocality_level_2",
      "sublocality_level_3",
    ],
  },
  { id: "city", values: ["locality", "neighborhood"] },
  { id: "state", values: ["administrative_area_level_1"] },
  { id: "country", values: ["country"] },
];

export function getLocationFromPlace(place: google.maps.places.PlaceResult) {
  if (!isDefined(place.geometry)) {
    throw new Error("Must pass in defined `place`");
  }
  const { location } = place.geometry;
  if (!isDefined(location)) {
    return LA_CENTER;
  }
  return {
    lat: location.lat(),
    lng: location.lng(),
  };
}

function getAddressType(value: string): AddressIdAndValues | undefined {
  return ADDRESS_ID_AND_VALUES.find(({ values }) => values.includes(value));
}

type InternalParsedAddress = Record<
  string,
  {
    type?: string;
    value: string;
  }
>;
/**
 * Find an index of a subset of ADDRESS_ID_AND_VALUES.
 * Specifically, `region` can have multiple `values`.
 * This identifies the last `region` from the parsedAddress.
 */
const isLowLevelType = (
  parsedAddress: InternalParsedAddress,
  values: AddressIdAndValues["values"],
  type: string
) => {
  const address = parsedAddress[type];
  if (!address || !isDefined(address.type)) {
    return false;
  }

  /**
   *  * Note, this is really confusing, and probably not even right.
   * The original code was broken.
   * It used to reference `values.index(type)` which is invalid.
   * See: https://github.com/ClipboardHealth/cbh-admin-frontend/blob/7a5e59caf22114a2cdbeefe74a7c5026319754e7/src/utils/googleMap.ts#L68
   */
  return values.indexOf(type) > values.indexOf(address.type);
};

export type GeoAddress = Partial<Address>;
export function parseAddressComponents(
  addressComponents: google.maps.GeocoderAddressComponent[]
): GeoAddress {
  const address: InternalParsedAddress = {};
  addressComponents.forEach((component) => {
    const [type] = component.types;

    const addressType = getAddressType(type);

    if (isEmpty(addressType)) {
      return;
    }

    const isLowLevel = isLowLevelType(address, addressType.values, type);

    if (!isLowLevel) {
      address[addressType.id] = { type, value: component.long_name };
    }

    if (addressType.id === "state") {
      address.stateCode = { value: component.short_name };
    }
    if (addressType.id === "country") {
      address.countryCode = { value: component.short_name };
    }
  });

  return mapValues(address, ({ value }) => value);
}

export async function getAddressByGeoCode(location: Location): Promise<Address> {
  const { lat, lng } = location;
  try {
    const [place] = await getGeocode({
      location: { lat, lng },
    });
    const geocodeAddress = parseAddressComponents(place.address_components);
    return { ...geocodeAddress, formatted: place.formatted_address };
  } catch {
    console.error("Invalid Address");
    return {};
  }
}
