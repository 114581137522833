import { isPlatform } from "@ionic/react";

import { FirebaseAuthentication } from "./authentication";
import { firebaseConfig } from "../../../../appV2/Auth/firebase";
import { environmentConfig } from "../../../../appV2/environment";

function initFirebaseAuth(): FirebaseAuthentication {
  const firebaseAuth: FirebaseAuthentication = new FirebaseAuthentication(
    firebaseConfig as unknown as Record<string, string>,
    {
      bundleId: environmentConfig.REACT_APP_FIREBASE_BUNDLE_ID,
      dynamicLinkDomain: environmentConfig.REACT_APP_FIREBASE_DYNAMIC_LINK_DOMAIN,
      appDomain: isPlatform("capacitor") ? environmentConfig.REACT_APP_WEB_APP_URL : "",
    }
  );

  return firebaseAuth;
}

/**
 * `firebaseAuth` depends on `isPlatform("capacitor")`, which depends
 * on `win.matchMedia` to be mocked in tests.
 * This mock has to be defined prior to running tests. Our
 * testing configuration doesn't evaluate it right away, so
 * we need to lazy evaluate the singleton.
 */
let firebaseAuthSingleton: FirebaseAuthentication;

export function getFirebaseSingleton() {
  if (!firebaseAuthSingleton) {
    firebaseAuthSingleton = initFirebaseAuth();
  }
  return firebaseAuthSingleton;
}
