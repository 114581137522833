import { InAppBrowser } from "@ionic-native/in-app-browser";

type BrowserTarget = "_system" | "_blank" | "_self";

export function openBrowser(url: string, target: BrowserTarget = "_system") {
  InAppBrowser.create(url, target);
}

export function openInAppBrowser(url: string, target: BrowserTarget = "_blank") {
  InAppBrowser.create(url, target, {
    location: "no",
    hidenavigationbuttons: "yes",
    toolbarposition: "bottom",
    closebuttoncaption: "Done",
    closebuttoncolor: "#ffffff",
    toolbarcolor: "#001529",
    toolbartranslucent: "no",
    footer: "yes",
    footercolor: "#001529",
    zoom: "no",
    useWideViewPort: "no",
  });
}
